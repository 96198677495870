import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { updateStatus, removeSummaryItem } from "../store/reducers/status";
import * as status_const from "../store/reducers/status";
import * as modal_constants from "../utils/modal_constants";
import * as utils from "../utils/utils";
import _ from "lodash";

class ChooseEmail extends Component {
  reset() {
    this.props.dispatch(updateStatus(status_const.RESET, null));
    this.props.onCancel.apply();
  }

  // removeItem(uuid) {
  //   const { dispatch } = this.props
  //   // let data = summary.filter(i => {
  //   //   return i.uuid !== uuid;
  //   // });
  //   //RECALC
  //   dispatch(removeSummaryItem(uuid))
  //   this.props.onCancel.apply();
  // }

  async removeItem(uuid) {
    const { dispatch } = this.props;
    let { summary } = this.props.status;
    let data = summary.filter(i => {
      return i.uuid !== uuid;
    });
    //RECALC
    let s = await utils.recalc(_.cloneDeep(data), this.props.groups);
    const t = utils.calculateTotal(s);
    dispatch(removeSummaryItem({ summary: s, total: t }));
    this.props.onCancel.apply();
  }

  cancel() {
    this.props.onCancel.apply();
  }

  click(modal_type) {
    if (modal_type === modal_constants.MODAL_DELETE_ITEM) {
      this.removeItem(this.props.uuid);
    } else {
      this.reset();
    }
  }

  render() {
    let { modal_type } = this.props;
    let title = "dialogs.confirm_reset";
    let action = "actions.reset";

    if (modal_type === modal_constants.MODAL_DELETE_ITEM) {
      title = "dialogs.confirm_delete";
      action = "actions.delete";
    }

    return (
      <div className="flex flex-center items-center flex-column justify-center">
        <div className="h3">
          <FormattedMessage id={title} />
        </div>

        <div className="flex flex-center items-center justify-around mt2 mb2">
          <button
            onClick={() => this.cancel()}
            className="btn-action btn-big mx1"
          >
            <FormattedMessage id="actions.cancel" />
          </button>
          <button
            onClick={() => this.click(modal_type)}
            className="btn-action btn-big mx1"
          >
            <FormattedMessage id={action} />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    status: state.status,
    groups: state.srv.tree.groups
  };
}

export default connect(mapStateToProps)(ChooseEmail);
