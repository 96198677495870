import { connect } from "react-redux";
import React, { Component } from "react";

import { IntlProvider, addLocaleData } from "react-intl";
import { flattenMessages } from "../utils/utils";
import * as messages from "../messages";

import en from "react-intl/locale-data/en";
import it from "react-intl/locale-data/it";
import Routes from "../components/routes";
//import { switchLocale } from "../store/reducers/ui";
addLocaleData([...en, ...it]);


let lang =
	(navigator.languages && navigator.languages[0]) ||
	navigator.language ||
	navigator.userLanguage ||
	"en";

class Root extends Component {
	render() {
		let { locale } = this.props;
		// console.log("locale", locale);
		if (!locale) {
			locale = lang.substring(0, 2);
			//	this.props.switchLocale(locale);
		}
		return (
			<IntlProvider
				locale={locale}
				messages={flattenMessages(messages[locale])}
			>
				<Routes />
			</IntlProvider>
		);
	}
}

function mapStateToProps(state) {
	return {
		locale: state.ui.locale
	};
}

// function mapDispatchToProps(dispatch) {
// 	return {
// 		switchLocale: locale => dispatch(switchLocale(locale))
// 	}
// }

export default connect(mapStateToProps)(Root);
