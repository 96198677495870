export const servicePlaceholder = {
  modelType: "service",
  id: "",
  title: "Articolo Magazzino",
  isUnique: true,
  isGroupUnit: false,
  groupMultiplier: null,
  group: null,
  unitPrices: [
    {
      id: "2071462",
      price: 100,
      title: "pu magazzino",
      discount: null,
      range: null,
      unit: { id: "128876", title: "Quantità" }
    }
  ],
  color: "rgba(89,192,177,1)",
  type: "service",
  level: 1,
  isExternalArticle: 1
};

export const articlePlaceholder = {
  id: "",
  level: 1,
  parent: "2071464",
  type: "service",
  title: "",
  index: 0,
  key: "",
  service: servicePlaceholder,
  uuid: "-",
  quantity: 1,
  unit: servicePlaceholder.unitPrices[0],
  descr: "Quantità 1",
  steps: [
    {},
    {
      id: "2071450",
      level: 0,
      parent: "2071450",
      type: "category",
      title: "Magazzino",
      position: 7,
      index: 10,
      key: "93368850-540e-4c9c-90e1-41e41e989d05_pos_10",
      service: null
    }
  ],
  total: 1,
  isExternalArticle: 1
};
