import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as status_const from '../store/reducers/status';
import { updateStatus } from '../store/reducers/status';
import { sendToServer, productMovement, sendToScloby } from '../api';
import { calculateTotal } from '../utils/utils';
import { FiMail } from 'react-icons/fi';
const { REACT_APP_ENDPOINT_URL: ENDPOINT_URL } = process.env;

class SendToScloby extends Component {
  constructor(props) {
    super(props);

    let { info } = props.status;
    let email = info && info.email ? info.email : null;
    let recipients = email ? [email] : email;

    this.state = {
      loading: true,
      message: null,
      recipients,
      recordId: null,
      link: null,
      note: null,
      sclobed: false,
    };
  }

  componentDidMount() {
    this.save();
  }

  reset() {
    this.props.dispatch(updateStatus(status_const.RESET, null));
    this.props.onCancel.apply();
  }

  async doAction(type, isInvoice, message) {
    const { recordId } = this.state;
    console.log('doAction', recordId);
    const { place } = this.props;
    const { summary, info, discount } = this.props.status;
    const { recipients, note } = this.state;
    // console.log('SUMMARY', summary);
    const total = calculateTotal(summary);
    try {
      const data = {
        total,
        place,
        recipients,
        info,
        note,
        isInvoice,
        summary,
        discount,
      };

      let movements = [];
      if (isInvoice && !recordId) {
        movements = summary.reduce((list, item) => {
          if (item.isExternalArticle) {
            let code = item.id;
            let store = place.id;
            let amount = item.quantity;
            let action = 'rem';
            let movement = { code, store, amount, action };
            list.push(movement);
          }
          return list;
        }, []);
      }
      console.log('sendToServer', data);
      const result = await sendToServer(data, type, recordId);
      const { id } = result;
      const currentId = id ? id : recordId;

      try {
        const result = await sendToScloby(currentId);
        if (result.error) {
          throw result.error;
        }
      } catch (e) {
        console.log('SCLOBY ERROR', e);
        this.setState({
          loading: false,
          message: 'errors.scloby_error',
          sclobed: false,
        });
        return;
      }

      if (movements.length > 0) {
        const movementPromises = movements.map((m) => {
          return productMovement(m);
        });
        await Promise.all(movementPromises);
      }

      this.setState({
        loading: false,
        link: result.url,
        recordId: currentId,
        sclobed: true,
        message,
      });
      // console.log(data)
    } catch (e) {
      this.setState({
        loading: false,
        message: 'errors.generic_error',
      });
    }
  }

  openUrl(id) {
    const url = `${ENDPOINT_URL}/summaries/print/${id}`;
    window.open(url, '_blank');
  }

  //SAVE
  save() {
    console.log('SAVE');
    const type = 'save';
    const message = 'dialogs.sent_to_scloby';
    const isInvoice = true;
    this.setState({ loading: true, message: null });
    this.doAction(type, isInvoice, message);
  }

  render() {
    let { loading, message, recordId, sclobed } = this.state;
    let show_buttons = !(loading || message);

    return (
      <div className="flex flex-center items-center flex-column justify-center">
        {recordId && <div>Ordine Numero: {recordId}</div>}
        {loading && (
          <div>
            <FormattedMessage id="actions.loading" />
          </div>
        )}

        {message && (
          <div className="flex flex-center items-center flex-column justify-center ">
            <div className="m1 result-message">
              {message && (
                <h3>
                  <FormattedMessage id={message} />
                </h3>
              )}
            </div>
          </div>
        )}

        {show_buttons && !sclobed && (
          <button
            onClick={(e) => this.save(true)}
            className="btn-action btn-big mx1 mb1"
          >
            <FormattedMessage id="dialogs.register" />
          </button>
        )}

        {sclobed && (
          <div className="full-w">
            <div className="flex flex-center items-center justify-center mt2 mb2">
              <div>
                <button
                  className="btn-round"
                  onClick={(e) => this.openUrl(recordId)}
                >
                  <FiMail className="react_icon" />
                </button>
                <span>Apri PDF</span>
              </div>
            </div>
            <div className="flex flex-center items-center justify-center mt2 mb2">
              <button
                onClick={() => this.reset()}
                className="btn-action btn mx1 mb1"
              >
                <FormattedMessage id="actions.reset" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    place: state.ui.place,
    status: state.status,
  };
}

export default connect(mapStateToProps)(SendToScloby);
