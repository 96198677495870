import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";
import Calculator from "./calculator";
import * as modal_constants from "../utils/modal_constants";
import { customPrice, customTotal } from "../store/reducers/status";

class ChoosePrice extends Component {
  constructor(props) {
    super(props);
    this.state = { total: null, unit: null };
  }

  setTotal(total) {
    this.setState({ total });
  }

  setUnit(unit) {
    this.setState({ unit });
  }

  cancel() {
    if (this.props.onCancel) {
      return this.props.onCancel.apply();
    }
  }

  click() {
    let { total, unit } = this.state;
    let { current_item, dispatch } = this.props;
    let item = _.cloneDeep(current_item);

    //check what price is changing
    if (this.props.modal_type === modal_constants.MODAL_PRICE_UNIT) {
      //pu
      if (!unit) return;
      item.custom_total = current_item.quantity * unit;
      dispatch(customPrice(item));
    } else {
      //total
      if (!total) return;
      item.custom_total = total;
      dispatch(customTotal(item));
    }

    //cb
    if (this.props.onClick) {
      return this.props.onClick.apply();
    }
  }

  componentDidMount() {
    //init
    let { current_item } = this.props;
    let total = current_item.custom_total
      ? current_item.custom_total
      : current_item.total;
    let unit = current_item.unit.price / 100;
    this.setState({ total, unit });
  }

  render() {
    // let val =
    //   this.props.modal_type === modal_constants.MODAL_PRICE_UNIT
    //     ? this.state.unit
    //     : this.state.total;

    let cb =
      this.props.modal_type === modal_constants.MODAL_PRICE_UNIT
        ? this.setUnit.bind(this)
        : this.setTotal.bind(this);

    //value={val}
    return (
      <div className="full-w">
        <div className="modal-content-body">
          <div className="flex flex-center justify-center">
            <Calculator
              value={null}
              passValue={cb}
              type={this.props.type}
              modal_type={this.props.modal_type}
              ok={this.click.bind(this)}
              cancel={this.cancel.bind(this)}
            />
          </div>
        </div>
        <div className="mt2 full-w flex flex-center align-center justify-center center">
          <button
            onClick={() => this.props.onCancel()}
            className="btn-action--light mr1"
          >
            <FormattedMessage id={"actions.cancel"} />
          </button>
          <button onClick={() => this.click()} className="btn-action--dark ml1">
            <FormattedMessage id={"actions.confirm"} />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ status }) {
  const { current_item } = status;
  return {
    current_item
  };
}

export default connect(mapStateToProps)(ChoosePrice);
