import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as status_const from '../store/reducers/status';
import { updateStatus } from '../store/reducers/status';
import _ from 'lodash';
import moment from 'moment';

class SetInfo extends Component {
  constructor(props) {
    super(props);
    const fmt = 'YYYY-MM-DD';
    const { info } = this.props;

    const title = _.get(info, 'title', '');
    const email = _.get(info, 'email', '');
    const phone = _.get(info, 'phone', '');
    const note = _.get(info, 'note', '');
    const date = _.get(info, 'date', moment().format(fmt));
    const isCompany = _.get(info, 'isCompany', false);
    const processing_notes = _.get(info, 'processing_notes', '');

    this.state = {
      title,
      email,
      phone,
      note,
      date,
      isCompany,
      processing_notes,
    };
  }

  save() {
    const {
      title,
      email,
      phone,
      note,
      date,
      isCompany,
      processing_notes,
    } = this.state;
    if (title || email)
      this.props.updateStatus(status_const.SET_INFO, {
        title,
        email,
        phone,
        note,
        date,
        isCompany,
        processing_notes,
      });

    this.props.onCancel.apply();
  }

  onChange(e) {
    let tmp = { [e.target.name]: e.target.value.trim() };
    if (tmp) {
      this.setState(tmp);
    }
  }
  render() {
    let {
      title,
      email,
      phone,
      note,
      date,
      isCompany,
      processing_notes,
    } = this.state;
    return (
      <div className="flex flex-center items-center flex-column justify-center">
        <div className="flex flex-center items-center flex-row justify-center">
          <div>
            <label className="label">
              <FormattedMessage id="labels.title" />
            </label>
            <input
              type="text"
              className="input"
              name="title"
              onChange={this.onChange.bind(this)}
              defaultValue={title}
            />
          </div>

          <div className="ml1 mr1">
            <label className="label">
              <FormattedMessage id="labels.email" />
            </label>
            <input
              type="email"
              className="input"
              name="email"
              onChange={this.onChange.bind(this)}
              defaultValue={email}
            />
          </div>
          <div>
            <label className="label">
              <FormattedMessage id="labels.phone" />
            </label>
            <input
              type="tel"
              className="input"
              name="phone"
              onChange={this.onChange.bind(this)}
              defaultValue={phone}
            />
          </div>
        </div>

        <div className="full-w">
          <label className="label">
            <FormattedMessage id="labels.isCompany" />
            <input
              type="checkbox"
              name="isCompany"
              onChange={(e) => this.setState({ isCompany: !isCompany })}
              checked={isCompany}
            />
          </label>
        </div>

        <div className="full-w">
          <label className="label">
            <FormattedMessage id="labels.delivery_date" />
          </label>
          <input
            type="date"
            className="input"
            name="date"
            onChange={this.onChange.bind(this)}
            defaultValue={date}
          />
        </div>

        <div className="full-w">
          <label className="label">
            <FormattedMessage id="labels.customer_notes" />
          </label>
          <textarea
            className="input full-w"
            name="note"
            onChange={this.onChange.bind(this)}
            style={{ minHeight: '80px' }}
            defaultValue={note}
          />
        </div>

        <div className="full-w">
          <label className="label">
            <FormattedMessage id="labels.note" />
          </label>
          <textarea
            className="input full-w"
            name="processing_notes"
            onChange={this.onChange.bind(this)}
            style={{ minHeight: '80px' }}
            defaultValue={processing_notes}
          />
        </div>

        <div>
          <button
            onClick={() => this.save()}
            className="btn-action btn-big mx1 bt1"
          >
            <FormattedMessage id="actions.confirm" />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    info: state.status.info,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: (type, data) => dispatch(updateStatus(type, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetInfo);
