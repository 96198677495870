import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true
    };

    this.show = this.show.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  onComponentDidMount() {
    if (this.props.active) {
      this.setState({ active: this.props.active });
    }
  }

  show() {
    this.setState({ active: true });
  }

  cancel() {
    if (this.props.onCancel) {
      return this.props.onCancel.apply();
    }

    return this.setState({
      active: false
    });
  }

  click() {
    // console.log("click");
    if (this.props.onClick) {
      return this.props.onClick.apply();
    }
    return this.setState({
      active: false
    });
  }

  render() {
    let c = "modal-container";
    c += this.state.active ? " active" : "";

    let cn = `modal-content`;
    let { type } = this.props;
    if (type) cn = `modal-${type}`;

    let ok_label = "actions.ok";
    if (this.props.ok_label) ok_label = this.props.ok_label;
    //let close_symbol = '&#x2715';
    return (
      <div id="modal" className={c}>
        <div className="modal-backdrop border">
          <div className={cn}>
            {this.props.cancel && (
              <div className="modal-close cancel" onClick={() => this.cancel()}>
                <span className="icon-medium icon-cross-white" />
              </div>
            )}
            {this.props.title && (
              <div className="flex items-center justify-center">
                <span className="h2 center mt2 mb2">
                  <FormattedMessage id={this.props.title} />
                </span>
              </div>
            )}
            <div className="modal-content-body">
              {React.cloneElement(this.props.children, { ...this.props })}
            </div>
            {this.props.ok && (
              <div className="flex items-center justify-center">
                <div
                  onClick={() => this.click()}
                  className="btn-action btn-big mx1 mb2 bt1 center flex items-center justify-center"
                >
                  <FormattedMessage id={ok_label} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  ok: true,
  cancel: true,
  title: null,
  type: null
};
