import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";
import _ from "lodash";

import Calculator from "./calculator";
import { setQuantity } from "../store/reducers/status";
import { getPrice } from "../utils/utils";

class ChooseQuantity extends Component {
  constructor(props) {
    super(props);
    this.state = { cat: null, qt: null };
  }

  setCat(cat) {
    this.setState({ cat });
  }

  setQt(qt) {
    this.setState({ qt });
  }

  cancel() {
    if (this.props.onCancel) {
      return this.props.onCancel.apply();
    }
  }

  click() {
    const { dispatch } = this.props;
    // console.log("CLICK");
    if (!this.state.qt) return;
    let { qt } = this.state;
    let { current_item } = this.props.status;

    let item = getPrice(_.cloneDeep(current_item), qt);
    item.custom_total = null;
    item.quantity = 0 + qt;

    dispatch(setQuantity(item));

    //SET CURRENT_ITEM  into the store
    // this.props.updateStatus(status_const.SET_CURRENT, update);
    if (this.props.onClick) {
      return this.props.onClick.apply();
    }
  }

  componentDidMount() {
    let { current_item } = this.props.status;
    let qt = current_item.quantity;
    let cat = current_item.unit.unit;
    this.setState({ qt, cat });
  }

  showCats() {
    let { current_item } = this.props.status;
    let units = current_item.service.units_prices;
    let cats = _.uniq(units.map(up => up.unit));
    return (
      <div className="flex flex-column flex-center">
        {cats.map(cat => {
          let checked = cat === this.state.cat;
          var btnClass = classNames({
            "btn m1": true,
            "btn-selected": checked,
            "btn-big": true
          });
          return (
            <button
              className={btnClass}
              key={cat}
              onClick={() => this.setCat(cat)}
            >
              <div className="btn-content btn-block">
                <span>{cat}</span>
                <span className="ml1">
                  {checked && <span className="icon-normal icon-ok-black" />}
                </span>
              </div>
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className="full-w">
        <div className="modal-content-body">
          <div className="flex flex-center justify-around">
            {/*
            this.showCats()

             value={this.state.qt}
          */}
            <div>
              <Calculator
                value={null}
                passValue={this.setQt.bind(this)}
                type={this.props.type}
                modal_type={this.props.modal_type}
                ok={this.click.bind(this)}
                cancel={this.cancel.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className="mt2 full-w flex flex-center align-center justify-center center">
          <button
            onClick={() => this.props.onCancel()}
            className="btn-action--light mr1"
          >
            <FormattedMessage id={"actions.cancel"} />
          </button>
          <button onClick={() => this.click()} className="btn-action--dark ml1">
            <FormattedMessage id={"actions.confirm"} />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    status: state.status
  };
}

export default connect(mapStateToProps)(ChooseQuantity);
