const frag = `fragment catFields on CategoryRecord {
  modelType: _modelApiKey
  id
  title
  position
  parent {
    id
  }
  services {
    modelType: _modelApiKey
    id
    title
    isUnique
    isGroupUnit
    groupMultiplier
    group {
      id
      title
    }
    unitPrices {
      id
      price
      title
      discount
      range {
        id
        min
        max
      }
      unit {
        id
        title
      }
    }
    scloby {
      id
      categoryName
      sku
    }
  }
}`;
//allCategories(filter: { parent: { exists: false } }, orderBy: position_ASC, first: 100)
export const initQuery = `
query GetCategories {
  categories: allCategories(filter: { parent: { exists: false } }, orderBy: position_ASC, first:100) {
    ...catFields
    children {
      ...catFields
      children {
        ...catFields
        children {
          ...catFields
        }
      }
    }
  }
}
${frag}
`;

export const allCategories = `
query allCategories($skip:IntType) {
  categories: allCategories( first:100, skip: $skip, orderBy: position_ASC) {
    ...catFields
  }
}
${frag}
`;

export const rootCategories = `
query rootCategories {
  categories: allCategories(filter: { parent: { exists: false } }, orderBy: position_ASC, first:100) {
    ...catFields
    children {
      id
    }
  }
}
${frag}
`;

export const categoriesByParent = `
query categoriesByParent($parent: ID!) {
  categories: allCategories(filter: { parent: { eq: $parent } }, orderBy: position_ASC, first:100) {
    ...catFields
    children {
      id
    }
  }
}
${frag}
`;

export const categoryById = `
query categoryById($id: ItemId) {
  category(filter: {id: {eq: $id}}) {
    ...catFields
    children {
      id
      title
    }
  }
}
${frag}
`;

export const queryGroups = `
query QueryGroups {
  groups: allServices(filter: {isGroupUnit: {eq: true}}, first: 100) {
    group {
      id
      title
    }
    isGroupUnit
    unitPrices {
      price
      title
      range {
        title
        min
        max
      }
      unit {
        id
        title
      }
      id
    }
  }
}
`;

export const queryPlaces = `
  query Places {
    allPlaces {
      id
      nick
      title
      addr
      mail
      tel
      slug
    }
  }`;
