import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

function Discount(props) {
  const { discount } = props;
  const [value, setValue] = useState(discount > 0 ? discount : "");

  const reset = () => {
    const payload = { discount: 0 };
    props.dispatch({ type: "SET_DISCOUNT", payload });
    props.onCancel.apply();
  };

  const save = () => {
    if (value && value !== discount) {
      const payload = { discount: value };
      props.dispatch({ type: "SET_DISCOUNT", payload });
    }
    props.onCancel.apply();
  };

  const onChange = e => {
    setValue(e.target.value);
  };

  return (
    <div className="flex flex-center items-center flex-column justify-center">
      <div className="full-w mt1 mb1">
        <label className="label">
          <FormattedMessage id="labels.discount" />
        </label>
        <input
          type="number"
          className="input"
          name="date"
          placeholder="%"
          onChange={onChange.bind(this)}
          value={value}
        />
      </div>

      <div className="flex flex-center items-center flex-row justify-center">
        <button onClick={() => reset()} className="btn-action btn-big mx1 bt1">
          <FormattedMessage id="actions.remove" />
        </button>

        <button onClick={() => save()} className="btn-action btn-big mx1 bt1">
          <FormattedMessage id="actions.confirm" />
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { discount } = state.status;
  return { discount };
}

export default connect(mapStateToProps)(Discount);
