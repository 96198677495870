import React, { Component } from 'react';
import * as modal_constants from '../utils/modal_constants';
import Modal from './modal';
import ChoosePlace from './choose_places';
import ChooseAction from './choose_action';
import ChooseQuantity from './choose_quantity';
import ChoosePrice from './choose_price';
import Confirm from './confirm';
import SetInfo from './set_info';
import Discount from './discount';
import SearchUser from './search_user';
import SearchDoc from './search_doc';
import LoadDoc from './load_doc';
import SearchProduct from './search_product';
import SendToScloby from './send_scloby';

export default class ModalContainer extends Component {
  render() {
    let { modal_type, modal_params } = this.props;
    let content = null;
    let opt = { title: null, type: null, ok: true, cancel: true };

    switch (modal_type) {
      case modal_constants.MODAL_SEARCH_PRODUCT:
        content = <SearchProduct />;
        opt = Object.assign(opt, {
          title: 'dialogs.products',
          ok: false,
          cancel: true,
        });
        break;
      case modal_constants.MODAL_SEARCH_USER:
        content = <SearchUser />;
        opt = Object.assign(opt, {
          title: 'dialogs.clients',
          ok: false,
          cancel: true,
        });
        break;
      case modal_constants.MODAL_SEARCH_DOC:
        content = <SearchDoc />;
        opt = Object.assign(opt, {
          title: 'dialogs.docs',
          ok: false,
          cancel: true,
        });
        break;
      case modal_constants.MODAL_LOAD_DOC:
        content = <LoadDoc />;
        opt = Object.assign(opt, {
          title: 'dialogs.load',
          ok: false,
          cancel: true,
        });
        break;

      case modal_constants.MODAL_SET_DISCOUNT:
        content = <Discount />;
        opt = Object.assign(opt, {
          title: 'dialogs.discount',
          ok: false,
        });
        break;
      case modal_constants.MODAL_CHOOSE_PLACE:
        content = <ChoosePlace />;
        opt = Object.assign(opt, {
          title: 'dialogs.choose_place',
        });
        break;
      case modal_constants.MODAL_INVOICE:
        content = <SendToScloby modal_type={modal_type} />;
        opt = Object.assign(opt, {
          title: 'dialogs.register',
          ok: false,
          ok_label: 'dialogs.back',
        });
        break;
      case modal_constants.MODAL_SUMMARY:
        content = <ChooseAction modal_type={modal_type} />;
        opt = Object.assign(opt, {
          title: 'dialogs.choose_action',
          ok: false,
          ok_label: 'dialogs.back',
        });
        break;
      case modal_constants.MODAL_RESET:
      case modal_constants.MODAL_DELETE_ITEM:
        content = <Confirm {...modal_params} modal_type={modal_type} />;
        opt = Object.assign(opt, {
          title: null,
          ok: false,
        });

        break;
      case modal_constants.MODAL_QUANTITY:
        console.log('modal_params', modal_params);
        content = <ChooseQuantity {...modal_params} modal_type={modal_type} />;
        opt = Object.assign(opt, {
          title: null,
          type: 'overlay',
          ok: false,
          cancel: false,
        });
        break;
      case modal_constants.MODAL_PRICE:
      case modal_constants.MODAL_PRICE_UNIT:
        content = <ChoosePrice {...modal_params} modal_type={modal_type} />;
        opt = Object.assign(opt, {
          title: null,
          type: 'overlay',
          ok: false,
          cancel: false,
        });
        break;

      case modal_constants.MODAL_SET_INFO:
        opt = Object.assign(opt, {
          title: 'dialogs.set_info',
          ok: false,
        });
        content = <SetInfo />;
        break;

      default:
        content = <div>no modal found</div>;
        break;
    }

    return (
      /*title={opt.title}
        type={opt.type}
        cancel={opt.cancel}
        ok={opt.ok}*/
      <Modal
        onCancel={this.props.modalClose}
        onClick={this.props.modalClik}
        {...opt}
      >
        {content}
      </Modal>
    );
  }
}
