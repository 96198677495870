import _ from 'lodash';
import produce from 'immer';
import { v4 } from 'node-uuid';
import moment from 'moment';

export const clone = (original) => {
  const clone = produce(original, (draft) => {
    draft.ts = Date.now();
  });
  return clone;
};

export function loadTree(categories, groups) {
  let flatten = [];
  function recurse(cats, depth = 0) {
    cats.forEach((cat) => {
      if (cat.children && cat.children.length > 0) {
        recurse(cat.children, depth + 1);
      }

      if (cat.services && cat.services.length > 0) {
        const color = genRandomColor();
        const services = cat.services.map((service, i) => {
          // const { id, title, unitPrices, group, isGroupUnit } = service;
          service.unitPrices = genServiceUnits(_.cloneDeep(service), groups);

          service.color = hexToRGBA(color, 1 - i / 10);
          if (service.group) {
            const { id } = service.group;
            const group = groups.find((g) => g.group.id === id);
            service.color = group.color;
          }

          return service;
        });
        cat.services = _.cloneDeep(services);
      }

      const exists = flatten.find((c) => c.id === cat.id);
      if (!exists) {
        let clone = Object.assign({}, cat);
        delete clone.children;
        clone.level = depth;
        clone.parent = cat.parent ? cat.parent.id : -1;
        flatten.push(clone);
      }
    });
  }

  recurse(categories);
  flatten = _.orderBy(flatten, ['level', 'position'], ['asc', 'asc']);
  return flatten;
}

export const formatOrder = (order) => {
  let { total, summary, place } = order;
  if (!summary || summary.length === 0) return null;
  const formattedTotal = Math.round(total * 100) / 100;

  let info = `${formattedTotal}€ - ${summary.length} voci `;
  let when = moment().format();
  const voices = summary.map(async (voice) => {
    return {
      itemType: 'LINEA_DI_ORDINE',
      id: v4(),
      service: voice.service.id,
      up: voice.unit.id,
      quantity: voice.quantity,
      amount: voice.custom_total ? voice.custom_total * 100 : voice.amount,
      title: voice.path,
      scloby: voice.service.scloby ? voice.service.scloby : null,
    };
  });
  const invoice = {
    itemType: 'CONTO',
    id: v4(),
    order_lines: voices,
    place: place && place.id ? place.id : null,
    invoice: true,
    title: `${info} - ${when}`,
  };

  return invoice;
};

// export function formatSummary(summary) {
// 	if (summary.length === 0) return null;
// 	let summary_items = summary.map(item => {
// 		let { unit } = item;
// 		let { id, price, unit: unitInfo } = unit;
// 		let unit_price = {
// 			id,
// 			price,
// 			unit: unitInfo.title
// 		};
// 		let obj = {
// 			service: item.service.id,
// 			up: unit_price,
// 			quantity: item.quantity,
// 			amount: item.custom_total ? item.custom_total * 100 : item.amount,
// 			title: item.customTitle ? item.customTitle : item.path,
// 			type: item.path
// 		};
// 		return obj;
// 	});
// 	return summary_items;
// }

export const getItemPrice = (item) => {
  if (!item) return 0;
  let price = item.custom_total
    ? item.custom_total / item.quantity
    : item.unit.price / 100;

  const multiplier = item.service.groupMultiplier
    ? item.service.groupMultiplier
    : 1;

  if (multiplier > 1 && !item.custom_total) {
    price = price * multiplier;
  }

  return price;
};

export const getItemTotal = (item) => {
  if (!item) return 0;
  return item.custom_total ? item.custom_total : item.total;
};

//flatten labels
export const flattenMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const genServiceUnits = (service, groups) => {
  if (service.group && service.groupMultiplier > 1) {
    // const m = service.groupMultiplier;
    const group = groups.find((g) => g.group.id === service.group.id);
    return _.cloneDeep(sortUnits(group.unitPrices));

    //se modifico le up non tprna x4
    // const unitPrices = group.unitPrices.map(u => {
    // 	u.price = (u.pice / 100) * m * 100;
    // 	u.id = `${u.id}x${m}`;
    // 	return u;
    // });
    // return _.cloneDeep(sortUnits(unitPrices));
  }
  return _.cloneDeep(sortUnits(service.unitPrices));
};

const sortUnits = (units) => {
  //@TODO MOVE THIS SORTS WHEN FLATTEN TREE
  units.sort((a, b) => {
    return a.unit - b.unit;
  });
  //@TODO MOVE THIS SORTS WHEN FLATTEN TREE
  units.sort((a, b) => {
    if (a.range && b.range) {
      return a.range.min - b.range.min;
    } else {
      return a.unit - b.unit;
    }
  });
  return units;
};

export const hexToRGBA = (hex, alpha = 1) => {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);
  return `rgba(${red},${green},${blue},${alpha})`;
};

export const genRandomColor = (alpha = null) => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  if (alpha) return hexToRGBA(color, alpha);
  return color;
};

export const getBreadCrumbs = (selected_categories, title = null) => {
  const breadcrumbs = selected_categories
    .map((step) => {
      if (step && step.title) return step.title;
      return null;
    })
    .filter(Boolean);

  if (title) {
    breadcrumbs.push(title);
  }
  return breadcrumbs ? breadcrumbs.join(' ') : '';
};

//RECALC and split prices on items of summary
export const recalc = (summary, groups) => {
  return new Promise((resolve, reject) => {
    // console.log("RECALC");

    //from summary filter the ones with groups;
    let withgroups = summary.filter(
      (item) =>
        item.service.group != null && !item.custom_total && !item.is_unique
    );
    // console.log("WITHGROUPS", withgroups);

    //group items by group id
    const gg = _.chain(withgroups)
      .groupBy((item) => {
        return item.service.group.id;
      })
      .toPairs()
      .map((item) => _.zipObject(['group', 'items'], item))
      .value();

    //for each group
    let grouped = [];
    gg.forEach((i) => {
      let group_items = splitGroupPrice(i.group, i.items, groups);
      // reassignItems(summary, items);
      grouped = [...grouped, ...group_items];
    });

    //select the items without group
    const others = _.differenceBy(summary, withgroups, 'uuid');
    // console.log("OTHERS (no group)", others);

    //from others filter the ones that are replicated;
    const withSameService = _.chain(others)
      .countBy((item) => {
        return item.service.id;
      })
      .toPairs()
      .map((item) => _.zipObject(['id', 'count'], item))
      .filter((item) => item.count > 1 && !item.custom_total && !item.is_unique)
      .value();

    let replicated = [];
    // console.log("REPLICATED", withSameService);
    withSameService.forEach((i) => {
      let items = summary.filter((item) => item.service.id === i.id);
      items = splitPrice(items);
      replicated = [...replicated, ...items];
    });

    const rest = _.differenceBy(summary, [...grouped, ...replicated], 'uuid');

    summary = [...grouped, ...replicated, ...rest];
    summary = _.sortBy(summary, 'index');
    resolve(summary);
  });
};

export const calculateTotalDiscount = (total, discount = 0) => {
  if (discount > 0) {
    const perc = total * ((100 - discount) / 100);
    return perc.toFixed(3);
  }
  return total;
};

//calculate total by elements on summary

export const calculateTotal = (summary) => {
  if (!summary) return 0;

  let total = 0;
  if (summary && summary.length > 0) {
    total = summary.reduce((acc, val) => {
      if (val.custom_total) return acc;
      return acc + parseFloat(val.amount);
    }, 0);
  }
  total = parseFloat(total / 100);

  let custom_sum = summary.reduce((acc, val) => {
    if (!val.custom_total) return acc;
    return acc + parseFloat(val.custom_total);
  }, 0);

  let finalPrice = total + custom_sum;
  return finalPrice;
};

export const exactUnit = (original_units, cat, qt) => {
  let units = _.cloneDeep(original_units);

  const u = units
    .filter((up) => {
      if (up.range) {
        //get unit by range
        if (up.range.min <= qt && qt <= up.range.max) {
          return up;
        }
      } else {
        //get base unit
        return up;
      }
    })
    .filter(Boolean);

  let choosen = _.cloneDeep(units[0]);
  let tot = 0;

  if (u.length) {
    choosen = u[0];
    let index = units.findIndex((x) => x.id === choosen.id);
    tot = qt * choosen.price;
    // console.log("CHECK TOT", tot);
    if (units.length > index + 1) {
      let next = units[index + 1];
      if (next.range) {
        let next_tot = next.price * next.range.min;
        // console.log("NEXT TOT", next_tot);
        if (next_tot < tot) {
          choosen = _.cloneDeep(next);
          // let next_price = next.price;
          tot = next_tot;
          choosen.price = tot / qt;
          choosen.id = 'custom';
          choosen.title = 'custom';
          // console.log("USE NEXT PRICE", next_price);
        }
      }
    }
  }

  return choosen;
};

//choose range and price
export const chooseUnit = (original_units, cat, qt) => {
  // console.log("chooseUnit QT", qt);
  let units = _.cloneDeep(original_units);

  const u = units
    .filter((up) => {
      if (up.range) {
        //get unit by range
        if (up.range.min <= qt && qt <= up.range.max) {
          return up;
        }
      } else {
        //get base unit
        return up;
      }
    })
    .filter(Boolean);

  let choosen = _.cloneDeep(units[0]);
  let tot = 0;
  // console.log("CHOOSEN PRICE BY QT", choosen.price);

  if (u.length) {
    choosen = u[0];
    let index = units.findIndex((x) => x.id === choosen.id);
    tot = qt * choosen.price;
    // console.log("CHECK TOT", tot);
    if (units.length > index + 1) {
      let next = units[index + 1];
      if (next.range) {
        let next_tot = next.price * next.range.min;
        // console.log("NEXT TOT", next_tot / 100);
        if (next_tot < tot) {
          choosen = _.cloneDeep(next);
          // let next_price = next.price;
          tot = next_tot;
          choosen.price = tot / qt;
          choosen.id = 'custom';
          choosen.title = 'custom';
          // console.log("ADJUSTED PRICE", choosen.price);
          // console.log("USE NEXT PRICE", next_price);
        }
      }
    }
  }

  return choosen;
};

export function updateValues(item, unit) {
  // item.grouped = item.service.group != null;
  item.unit = unit;
  const { price } = unit;

  let multiplier =
    item.service.group && item.service.groupMultiplier
      ? item.service.groupMultiplier
      : 1;

  let p = price * multiplier;
  const amount = p * item.quantity;
  item.amount = amount;
  item.total = amount / 100;

  item.descr = `${item.unit.unit.title} ${item.quantity}`;
  return item;
}

export const getPriceWithSummary = (
  current_item,
  related = null,
  summary,
  groups
) => {
  // console.log("withSummary");
  if (!current_item || !current_item.service || !summary)
    return { current_item, related };

  const { service } = current_item;
  if (current_item.custom_total || service.is_unique)
    return { current_item, related };
  if (service.group) {
    const groupId = service.group.id;
    //from summary filter the ones with groups;
    let withgroups = summary.filter(
      (item) =>
        item.service.group != null &&
        !item.custom_total &&
        !item.is_unique &&
        item.service.group.id === groupId
    );
    // console.log("RELATED GROUPED", withgroups);
    // console.log("groups", groups);
    // console.log("group", groupId);
    if (_.isEmpty(withgroups)) return { current_item, related };

    withgroups.push(current_item);
    related = getGroupUnit(groupId, withgroups, groups);
  } else {
    const withSameService = summary.filter(
      (item) =>
        item.service.id === current_item.service.id &&
        !item.custom_total &&
        !item.is_unique
    );
    withSameService.push(current_item);
    // console.log("RELATED REPLICATED", withSameService);
    if (_.isEmpty(withSameService)) return { current_item, related };
    related = getReplicatedUnit(withSameService);
  }

  if (!related) return { current_item, related };
  current_item = updateValues(current_item, related.unit);
  return { current_item, related };
};

export const getPrice = (item, qt) => {
  let cat = item.unit.unit;
  let units = item.service.unitPrices;
  let unit = chooseUnit(units, cat, qt);
  item.quantity = qt;
  item.amount = unit.price * item.quantity;
  item.descr = `${item.unit.unit.title} ${item.quantity}`;
  item.total = item.amount / 100;
  item = updateValues(item, unit);
  // item.custom_total =
  // 	item.amount === total ? null : total / 100;
  return item;
};

export const getReplicatedUnit = (items) => {
  const qt = items.reduce((sum, item) => sum + item.quantity, 0);
  const cat = items[0].unit.unit;
  const units = items[0].service.unitPrices;
  const unit = chooseUnit(units, cat, qt);
  return { unit, qt };
};

export const splitPrice = (items) => {
  const { unit } = getReplicatedUnit(items);
  return items.map((item) => {
    return updateValues(item, unit);
  });
};

export const getGroupUnit = (groupId, items, groups) => {
  const group = groups.find((g) => g.group.id === groupId);
  const cat = group.unitPrices[0].unit;
  const qt = items.reduce((sum, item) => {
    sum += item.quantity * item.service.groupMultiplier;
    return sum;
  }, 0);

  // console.log("GROUP QUANTITY", qt);
  const unit = chooseUnit(group.unitPrices, cat, qt);

  // console.log("CHOOSEN UNIT", unit);
  return { unit, qt };
};

export const splitGroupPrice = (groupId, items, groups) => {
  // console.log("SPLIT GROUP PRICE", groupId);
  const { unit } = getGroupUnit(groupId, items, groups);
  return items.map((item) => {
    item = updateValues(item, unit);
    return item;
  });
};
