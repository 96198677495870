import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { loadState, saveState } from "../utils/storage";
import _ from "lodash";
import reducer from "./reducers";

// const state = loadState();
// const cache = loadStorageCache();
// const persistedState = { ...state, ...cache };
// console.log("PERSISTED-STATE", persistedState);

const persistedState = loadState();

const store = createStore(
  reducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);

store.subscribe(
  _.throttle(() => {
    saveState({
      ui: store.getState().ui
    });
  }, 1000)
);

export default function configureStore(onCompletion) {
  if (onCompletion) onCompletion.apply();
  return store;
}
