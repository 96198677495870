import { init, getGroups, getPlaces } from '../../api';

import { saveCache } from '../../utils/storage';
import { loadTree } from '../../utils/utils';

export const GET_TREE = 'GET_TREE';
export const PLACE_ORDER = 'PLACE_ORDER';
export const GET_PLACES = 'GET_PLACES';
export const GET_TYPES = 'GET_TYPES';
export const CACHE_DATA = 'CACHE_DATA';
export const LOAD_CACHE = 'LOAD_CACHE';
export const CLEAR_CACHE = 'CLEAR_CACHE';

export const actionTree = (data) => ({ type: GET_TREE, payload: data });
export const actionOrder = (data) => ({ type: PLACE_ORDER, payload: data });
export const actionPlaces = (data) => ({ type: GET_PLACES, payload: data });
export const actionSetCache = (data) => ({ type: CACHE_DATA, payload: data });
export const actionLoadCache = (data) => ({ type: LOAD_CACHE, payload: data });
export const clearCache = () => ({ type: CLEAR_CACHE });

export const loadAll = () => {
  return async (dispatch) => {
    try {
      const data = await init();
      const groups = await getGroups();
      const flatten = await loadTree(data.categories, groups);
      const tree = {
        tree: data.categories,
        flatten,
        groups,
      };
      console.log('tree', tree);
      const places = await getPlaces();
      console.log('places', places);

      const cache = {
        places,
        tree,
        when: Date.now(),
      };
      saveCache({
        srv: cache,
      });

      dispatch(actionSetCache(cache));
    } catch (ex) {
      console.log('ERROR', ex);
    }
  };
};

export const setCache = (data) => {
  return (dispatch) => dispatch(actionSetCache(data));
};

export const loadCache = (data) => {
  return (dispatch) => dispatch(actionLoadCache(data));
};

const initialState = {
  places: null,
  tree: null,
  invoice: null,
  types: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PLACES:
      // console.log("get PLACES", JSON.stringify(action.payload, null, 2));
      return {
        ...state,
        places: action.payload,
      };
    case GET_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case GET_TREE:
      // console.log("get TREE", JSON.stringify(action.payload, null, 2));
      return {
        ...state,
        tree: action.payload,
      };
    case PLACE_ORDER:
      return {
        ...state,
        invoice: action.payload,
      };
    case CACHE_DATA:
    case LOAD_CACHE:
      return {
        ...state,
        places: action.payload.places,
        tree: action.payload.tree,
      };
    case CLEAR_CACHE:
      return {
        ...state,
        places: null,
        tree: null,
        invoice: null,
        types: null,
      };
    default:
      return state;
  }
};
