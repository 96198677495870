import { combineReducers } from "redux";

import ui from "./ui";
import srv from "./srv";
import status from "./status";

const reducer = combineReducers({
  ui,
  srv,
  status
});
export default reducer;
