export const MODAL_RESET = "MODAL_RESET";
export const MODAL_CHOOSE_PLACE = "MODAL_CHOOSE_PLACE";
export const MODAL_INVOICE = "MODAL_INVOICE";
export const MODAL_SUMMARY = "MODAL_SUMMARY";
export const MODAL_DELETE_ITEM = "MODAL_DELETE_ITEM";
export const MODAL_QUANTITY = "MODAL_QUANTITY";
export const MODAL_QUANTITY_SUMMARY_ITEM = "MODAL_QUANTITY_SUMMARY_ITEM";
export const MODAL_PRICE = "MODAL_PRICE";
export const MODAL_PRICE_UNIT = "MODAL_PRICE_UNIT";
export const MODAL_PRICE_SUMMARY_ITEM = "MODAL_PRICE_SUMMARY_ITEM";
export const MODAL_SET_INFO = "MODAL_SET_INFO";
export const MODAL_SET_DISCOUNT = "MODAL_SET_DISCOUNT";
export const MODAL_SEARCH_USER = "MODAL_SEARCH_USER";
export const MODAL_SEARCH_DOC = "MODAL_SEARCH_DOC";
export const MODAL_LOAD_DOC = "MODAL_LOAD_DOC";
export const MODAL_SEARCH_PRODUCT = "MODAL_SEARCH_PRODUCT"
