import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { authorize } from "../store/reducers/ui";
import { login } from "../api";
import moment from "moment";
import logo from "../assets/images/logo.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  // fakeLogin() {
  //   let someMin = moment().add(3, "h").format("x");
  //   let fake = { user: "lore", hash: "12312213", expire: someMin };
  //   this.props.authorize(fake);
  // }

  onSubmit(e) {
    e.preventDefault();
    console.log("SUBMIT")

    let username = this.username.value;
    let password = this.password.value;
    console.log("click", username, password);
    if (!username || !password) {
      this.setState({ error: "errors.auth_error", loading: false });
    } else {
      login({ username, password })
        .then((response) => {
          console.log("response", response);
          if (response.status === 200 && response.auth) {
            this.props.authorize(response.auth);
          } else {
            this.setState({ error: "errors.auth_error", loading: false });
          }
        })
        .catch((error) => {
          console.log("catch", error);
          this.setState({ error: "errors.generic_error", loading: false });
        });
    }
  }

  render() {
    let { loading, error } = this.state;
    return (
      <div className="wrap bg_yellow">
        <div className="layout__login">
          <div className="full-w center flex-center ">
            <img
              className="logo-login full-w center flex-center"
              src={logo}
              alt="Copisteria Universale"
            />
          </div>
          {!loading && (
            <form
              className="full-w center flex-center"
              onSubmit={(e) => this.onSubmit(e)}
            >
              <div className="h2 full-w m2 center">
                <FormattedMessage id="actions.login" />
              </div>
              {error && <FormattedMessage id={error} />}
              <input
                className="input full-w m2"
                name="username"
                type="text"
                ref={(input) => (this.username = input)}
              />
              <input
                className="input full-w m2"
                name="password"
                type="password"
                ref={(input) => (this.password = input)}
              />
              <div className="full-w center ">
                <button
                  enabled={this.username && this.password}
                  type="submit"
                  className="btn-action--dark btn-block full-w mx2 center"
                >
                  <FormattedMessage id="actions.login" />
                </button>
              </div>
            </form>
          )}
          {loading && (
            <div>
              <FormattedMessage id="actions.loading" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.ui.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authorize: (payload) => dispatch(authorize(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
