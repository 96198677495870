import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";

import * as status_const from "../store/reducers/status";
import { updateStatus } from "../store/reducers/status";
import { searchUser, searchUserByName, lastUsers } from "../api";

const SerachUser = (props) => {
  const { dispatch, info } = props;
  const [results, setResults] = useState(null);
  const [last, setLast] = useState([]);
  const [email, setEmail] = useState("");
  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const search = async (e) => {
    e.preventDefault();
    if (!email && !term) return null;
    setLoading(true);

    let data = null;
    if (email) {
      console.log("search email", email);
      data = await searchUser(email);
    } else {
      console.log("search term", term);
      data = await searchUserByName(term);
    }
    console.log("data", data);
    if (data) setResults(data);
    setLoading(false);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangeTerm = (e) => {
    setTerm(e.target.value);
  };

  const selectUser = (user) => {
    console.log("user", user);
    const { name: title, tel: phone, email } = user;
    const payload = {
      ...info,
      title,
      email,
      phone,
    };
    dispatch(updateStatus(status_const.SET_INFO, payload));
    props.onCancel();
  };

  const retrieveLastUsers = async () => {
    const lastRecords = await lastUsers();
    if (lastRecords) {
      setLast(lastRecords);
    }
  };

  useEffect(() => {
    //last users
    retrieveLastUsers();
  }, []);

  const reset = () => {
    setResults(null);
    setEmail("");
    setTerm("");
  };

  return (
    <div className="flex flex-column flex-center  m3">
      <div className="border p2">
        <form onSubmit={(e) => search(e)}>
          <div className="flex   items-center mb1">
            <div className="mr1">
              <label className="label">
                <FormattedMessage id="actions.email" />
              </label>
              <input
                className="input "
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => handleChangeEmail(e)}
              />
            </div>
            <div className="mr1">
              <label className="label">Nome o Telefono</label>
              <input
                className="input "
                type="text"
                value={term}
                placeholder="nome o tel"
                onChange={(e) => handleChangeTerm(e)}
              />
            </div>
            <button className={"btn btn-small"} type="submit">
              <FormattedMessage id="actions.search" />
            </button>
            <button
              className={"btn btn-small"}
              type="reset"
              onClick={(e) => reset()}
            >
              X
            </button>
          </div>
        </form>

        <div className="mb2">
          {loading && <FormattedMessage id="actions.loading" />}
          {!!_.isEmpty(results) && <FormattedMessage id="labels.no_results" />}
        </div>

        {!_.isEmpty(results) && (
          <div>
            <h5>Risultati</h5>
            {results &&
              results.map((user) => {
                return (
                  <div
                    className={"mb1 btn-simple"}
                    key={user.id}
                    onClick={() => selectUser(user)}
                  >
                    <div className=" flex flex-column p1">
                      <div className="flex justify-between">
                        <span>
                          {user.email} - {user.name}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {_.isEmpty(results) && last && (
        <div className="mt2">
          <div>
            <h5>Utenti Recenti</h5>
          </div>
          {last &&
            last.length > 0 &&
            last.map((user) => (
              <div
                className={"mb1 btn-simple"}
                key={user.id}
                onClick={() => selectUser(user)}
              >
                <div className=" flex flex-column p1">
                  <div className="flex justify-between">
                    <span>
                      {user.email} - {user.name}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    info: state.status.info,
  };
}

export default connect(mapStateToProps)(SerachUser);
