import React, { Component } from "react";
import classNames from "classnames";

export default class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      pristine: true,
      previous: null
    };
  }

  componentDidMount() {
    //let { value } = this.props;
    //if (value) this.setState({ value });
    this.myInput.focus();
  }

  componentWillReceiveProps(next) {
    let { value } = next;
    let previous = this.state.value;
    if (value && value !== this.state.value && value !== this.props.value) {
      this.setState({ value, previous });
    }
  }

  digit(d) {
    let { value, pristine } = this.state;

    if (!value) value = "";

    if (pristine) value = "" + d;
    else value += "" + d;
    //let rgx = new RegExp("^[0-9]+.?[0-9]+$");
    //if (rgx.test(value))
    pristine = false;

    try {
      //if(value) console.log(parseFloat(value));
      this.setState({ value, pristine });
      this.cb(value);
    } catch (err) {
      console.log(err);
    }
  }

  cb(value) {
    if (this.props.passValue) {
      try {
        this.props.passValue.call(null, parseFloat(value));
      } catch (ex) {
        console.log("ERROR PARSE", ex);
        this.props.passValue.call(ex);
      }
    }
  }

  increment() {
    let { value } = this.state;
    try {
      value = parseFloat(value);
      value++;
      this.setState({ value });
      this.cb(value);
    } catch (err) {
      console.log(err);
    }
  }

  decrement() {
    let { value } = this.state;

    try {
      value = parseFloat(value);
      value--;
      if (value < 0) value = 0;
      this.setState({ value });
      this.cb(value);
    } catch (err) {
      console.log(err);
    }
  }

  reset() {
    let { value } = this.state;
    value = null;
    let pristine = true;
    this.setState({ value, pristine });
    this.myInput.focus();
  }

  onChange(e) {
    let value = e.target.value;
    //console.log("CHANGE", value, this.state.pristine);
    if (this.state.pristine) {
      value = "" + value;
      value = value.substring(value.length - 1);
    }
    this.setState({ value: value, pristine: false });
    this.cb(value);
  }

  handleKeyPress(e) {
    console.log("KEYPRESS " + e.keyCode + " " +e.key);

    if (e.keyCode === 13 || e.key==="Enter") {
      if (this.props.ok) this.props.ok.call();
    }

    if (e.keyCode === 27 || e.key==="Escape") {
      if (this.props.cancel) this.props.cancel.call();
    }
  }

  render() {
    let { value } = this.state;
    let cn = classNames({
      "calculator__op subtract": true,
      inactive: value <= 0
    });
    let val = value === null ? "" : value;

    return (
      <div id="calculator-value" className="flex flex-center">
        <div className="calculator">
          <div className="calculator__top-bar">
            <button className={cn} onClick={() => this.decrement()}>
              -
            </button>
            <div className="calculator__display">
              <div id="calculator-value">
                <input
                  name="value"
                  type="tel"
                  ref={input => {
                    this.myInput = input;
                  }}
                  autoFocus
                  value={val}
                  onChange={e => this.onChange(e)}
                  onKeyPress={e => this.handleKeyPress(e)}
                  className="calc_input"
                />
              </div>
            </div>
            <button
              className="calculator__op add"
              onClick={() => this.increment()}
            >
              +
            </button>
          </div>
          <div className="calculator__keys">
            <div className="calculator__row">
              <button
                className="calculator__num"
                onClick={() => this.digit("7")}
              >
                7
              </button>
              <button
                className="calculator__num"
                onClick={() => this.digit("8")}
              >
                8
              </button>
              <button
                className="calculator__num"
                onClick={() => this.digit("9")}
              >
                9
              </button>
            </div>
            <div className="calculator__row">
              <button
                className="calculator__num"
                onClick={() => this.digit("4")}
              >
                4
              </button>
              <button
                className="calculator__num"
                onClick={() => this.digit("5")}
              >
                5
              </button>
              <button
                className="calculator__num"
                onClick={() => this.digit("6")}
              >
                6
              </button>
            </div>
            <div className="calculator__row">
              <button
                className="calculator__num"
                onClick={() => this.digit("1")}
              >
                1
              </button>
              <button
                className="calculator__num"
                onClick={() => this.digit("2")}
              >
                2
              </button>
              <button
                className="calculator__num"
                onClick={() => this.digit("3")}
              >
                3
              </button>
            </div>
            <div className="calculator__row">
              <button
                className="calculator__num"
                onClick={() => this.digit(".")}
              >
                .
              </button>
              <button
                className="calculator__num"
                onClick={() => this.digit("0")}
              >
                0
              </button>
              <button
                className="calculator__num clear"
                onClick={() => this.reset()}
              >
                C
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
