import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";

import * as modal_constants from "../utils/modal_constants";
import SummaryItem from "./summary_item";
import * as utils from "../utils/utils";
import { setStep } from "../store/reducers/status";
import { ReactComponent as Icon } from "../assets/icons/percentage.svg";

const Summary = props => {
  const { place, summary, dispatch, total, groups, discount } = props;

  const currencyProps = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  async function editItem(uuid, action) {
    switch (action) {
      case "del":
        props.showModal(modal_constants.MODAL_DELETE_ITEM, { uuid });
        break;
      case "edit":
        let s = _.cloneDeep(summary);

        const current_item = s.find(item => item.uuid === uuid);
        const { steps: selected_categories, level } = current_item;

        s = s.filter(item => item.uuid !== uuid);
        s = await utils.recalc(_.cloneDeep(s), groups);
        const total = utils.calculateTotal(s);

        const payload = {
          current_item,
          selected_categories,
          level,
          summary: s,
          total
        };
        dispatch(setStep(payload));
        break;
      default:
        console.log("unknow action");
        break;
    }
  }

  const renderFooter = () => {
    const total_discounted = utils.calculateTotalDiscount(total, discount);
    return (
      <div className="summary_footer ">
        <div className="summary_total">
          <FormattedMessage id="labels.total" />
        </div>

        <div
          className={`full-w  flex items-center ml1 mr1  ${
            discount > 0 ? "justify-between" : "justify-center "
          }`}
        >
          {total > 0 && (
            <div>
              <button
                className="btn-round mr1"
                onClick={() =>
                  props.showModal(modal_constants.MODAL_SET_DISCOUNT)
                }
              >
                <Icon style={{ width: 18, height: 18 }} />
              </button>
            </div>
          )}

          <div
            className={
              discount > 0 ? "summary_currency_striked" : "summary_currency"
            }
          >
            <FormattedNumber {...currencyProps} value={total} />
          </div>

          {discount > 0 && (
            <div className="summary_currency">
              <FormattedNumber {...currencyProps} value={total_discounted} />
            </div>
          )}
        </div>

        {summary && summary.length > 0 && place && (
          <div className="flex flex-center justify-between mt1 mb1">
            <button
              onClick={() => props.showModal(modal_constants.MODAL_INVOICE)}
              className="btn-action btn-action-summary mx1"
            >
              <FormattedMessage id="actions.invoice" />
            </button>
            <button
              onClick={() => props.showModal(modal_constants.MODAL_SUMMARY)}
              className="btn-action btn-action-summary mx1"
            >
              <FormattedMessage id="actions.summary" />
            </button>
          </div>
        )}

        {summary && summary.length > 0 && !place && (
          <button
            onClick={() => props.showModal(modal_constants.MODAL_CHOOSE_PLACE)}
            className="btn-action btn-action-summary mx1"
          >
            <FormattedMessage id="dialogs.choose_place" />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="layout__summary">
      <div className="summary__header full-w">
        <div className="center summary_title">
          <FormattedMessage id="labels.summary" />
        </div>
      </div>
      <div className="full-w summary_list">
        {summary &&
          summary.map(item => (
            <SummaryItem
              key={item.uuid ? item.uuid : item.key}
              item={item}
              click={editItem}
            />
          ))}
      </div>
      {renderFooter()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    place: state.ui.place,
    summary: state.status.summary,
    total: state.status.total,
    discount: state.status.discount,
    groups: state.srv.tree.groups
  };
}

export default connect(mapStateToProps)(Summary);
