import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import * as status_const from "../store/reducers/status";
import { updateStatus, addSummaryItem } from "../store/reducers/status";
import { searchSummaryByUser, getSummary, lastSummaries } from "../api";

const SerachDoc = (props) => {
  const { dispatch, info } = props;
  const [last, setLast] = useState([]);
  const [results, setResults] = useState(null);
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (info && info.email) {
      setEmail(info.email);
      search(null, info.email);
    }
  }, [info]);

  const retrieveLastDocs = async () => {
    const lastRecords = await lastSummaries();
    console.log("retrieveLastDocs", lastRecords);
    setLast(lastRecords);
  };

  useEffect(() => {
    //last docs
    retrieveLastDocs();
  }, []);

  const search = async (e, initialEmail = null) => {
    e && e.preventDefault();
    let emailValue = email ? email : initialEmail;
    if (!emailValue && !id) return null;
    let data = null;
    if (emailValue) {
      data = await searchSummaryByUser(emailValue);
    } else if (id) {
      const result = await getSummary(id);
      data = result ? [result] : [];
    } else {
      return null;
    }
    setLoading(true);

    console.log("data", data);
    setResults(data);
    setLoading(false);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeId = (e) => {
    setId(e.target.value);
  };

  const loadSummary = (data) => {
    console.log("summary", data);
    const { summary, total } = data;
    // const { title, phone, email } = data.info;
    const payload = {
      ...info,
      title: _.get(data, "info.title", null),
      email: _.get(data, "info.email", null),
      phone: _.get(data, "info.phone", null),
    };
    dispatch(updateStatus(status_const.SET_INFO, payload));
    dispatch(addSummaryItem({ summary, total }));
    props.onCancel();
  };

  const renderDocs = (docs) => {
    return docs.map((item) => {
      const { data, createdAt } = item;
      const { total, isInvoice, info } = data;
      const date = moment(createdAt).format("DD-MM-YYYY HH:mm:ss");
      const owner = info ? info : null;
      return (
        <div
          className={"mb1 btn-simple"}
          key={item.id}
          onClick={() => loadSummary(data)}
        >
          <div className=" flex flex-column p1">
            <div className="flex justify-between">
              <span>{`#${item.id}`}</span>
              <span>{`${isInvoice ? "Conto" : "Preventivo"}`}</span>
            </div>
            <div className="flex justify-between">
              <span>
                <strong>Importo:</strong> {`${total.toFixed(2)} €`}
              </span>
              <span>
                <strong>Data:</strong> {date}
              </span>
            </div>

            {owner && (
              <div className="flex justify-between">
                <span>
                  <strong>Cliente:</strong> {`${owner.title}`}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  const reset = () => {
    setResults(null);
    setEmail("");
    setId("");
  };

  return (
    <div className="flex flex-column flex-center  m3">
      <div className="border p2">
        <form onSubmit={(e) => search(e)}>
          <div className="flex  items-center  mb1">
            <div className=" mr1">
              <label className="label">
                <FormattedMessage id="actions.email" />{" "}
              </label>
              <input
                className="input "
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => handleChangeEmail(e)}
              />
            </div>
            <div className="mr1">
              <label className="label">
                <FormattedMessage id="actions.id" />
              </label>
              <input
                className="input"
                type="number"
                value={id}
                placeholder="number"
                onChange={(e) => handleChangeId(e)}
              />
            </div>
            <div className="flex  items-center">
              <button className={"btn btn-small mr1"} type="submit">
                <FormattedMessage id="actions.search" />
              </button>
              <button
                className={"btn btn-small mr1"}
                type="reset"
                onClick={(e) => reset()}
              >
                X
              </button>
            </div>
          </div>
        </form>

        <div className=" m1">
          {loading && <FormattedMessage id="actions.loading" />}

          {_.isEmpty(results) && <FormattedMessage id="labels.no_results" />}
        </div>

        {!_.isEmpty(results) && renderDocs(results)}
      </div>
      {_.isEmpty(results) && (
        <div className="mt2">
          <h5>Ultimi Ordini</h5>
          {renderDocs(last)}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    info: state.status.info,
  };
}

export default connect(mapStateToProps)(SerachDoc);
