import React, { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { getItemPrice, getItemTotal } from "../utils/utils";

const SummaryItem = props => {
  const [hover, setHover] = useState(false);
  const { item } = props;

  if (!item || !item.uuid) {
    console.log("empty", item);
    return null;
  }

  const currencyProps = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
  };
  const currencyProps2 = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  function onMouseEnterHandler() {
    setHover(true);
  }
  function onMouseLeaveHandler() {
    setHover(false);
  }

  function renderDot(c) {
    let color = c ? c : "#bbb";
    return <span className="dot" style={{ background: color }} />;
  }

  function renderSquare(service) {
    const { groupMultiplier: m } = service;
    let color = service.color ? service.color : "#bbb";
    return (
      <span>
        <span className="square" style={{ background: color }} />
        {m > 1 && <span> x{m}</span>}
      </span>
    );
  }

  function renderInfo() {
    return (
      <span>
        {item.service.group
          ? renderSquare(item.service)
          : renderDot(item.service.color)}
      </span>
    );
  }

  return (
    <div
      className="summary_list__item"
      onMouseEnter={() => onMouseEnterHandler()}
      onMouseLeave={() => onMouseLeaveHandler()}
      style={{ borderLeft: `2px solid ${item.service.color}` }}
    >
      <div className="flex items-center">
        <span className="icon icon-left-yellow accent" />
        <div className="full-w flex flex-column">
          <div className="flex justify-between">
            <div className="title">{item.customTitle? item.customTitle : item.path}</div>
            <div className="price">
              <FormattedNumber
                {...currencyProps2}
                value={parseFloat(getItemTotal(item))}
              />
            </div>
          </div>
          <div className="flex justify-between mx1">
            <div className="descr">
              <span className="mr1">
                <FormattedMessage id="labels.price_x_unit" />
              </span>
              <span>
                <FormattedNumber
                  {...currencyProps}
                  value={getItemPrice(item)}
                />
              </span>
            </div>
            <div className="descr">
              {item.custom_total && renderDot("red")}
              {renderInfo()}
            </div>
            <div className="descr">{item.descr}</div>
          </div>
          <div className="line" />
        </div>
      </div>

      {hover && (
        <div className="summary_list__item_overlay">
          <div className="full-w flex items-center justify-around flex-row">
            <button
              className="btn-oval"
              onClick={() => props.click(item.uuid, "edit")}
            >
              EDIT
            </button>
            <button
              className="btn-oval--dark"
              onClick={() => props.click(item.uuid, "del")}
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    status: state.status
  };
}

export default connect(mapStateToProps)(SummaryItem);
