import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as status_const from '../store/reducers/status';
import { updateStatus } from '../store/reducers/status';
import { sendToServer, sendEmail, productMovement, sendToScloby } from '../api';
import { calculateTotal } from '../utils/utils';
import * as modal_constants from '../utils/modal_constants';
import _ from 'lodash';
import {
  FiMail,
  FiExternalLink,
  FiLink,
  FiDownload,
  FiSend,
  FiCheckSquare,
  FiXSquare,
  FiXCircle,
} from 'react-icons/fi';
const { REACT_APP_ENDPOINT_URL: ENDPOINT_URL } = process.env;
const notaDelivery = `+3€ per pagamento in negozio, con pagamento anticipato all'ordine nessun costo di ritiro in store.`;

class ChooseAction extends Component {
  constructor(props) {
    super(props);

    const showInfo = _.get(props, 'status.info.showInfo', false) ? true : false;

    this.state = {
      loading: null,
      message: null,
      recipients: [],
      recordId: null,
      link: null,
      note: null,
      showMailForm: false,
      check: showInfo,
      checkDelivery: false,
      sclobed: false,
    };
  }

  componentDidMount() {
    let { info } = this.props.status;
    let email = info && info.email ? info.email : null;
    if (email) {
      let recipients = [email];
      this.setState({ recipients });
    }
  }

  reset() {
    this.props.dispatch(updateStatus(status_const.RESET, null));
    this.props.onCancel.apply();
  }

  onChangeRecipients(e) {
    let tmp = e.target.value;
    //console.log("typed:", tmp);
    if (tmp) {
      tmp = tmp.trim();
      let recipients = tmp.split(',');
      this.setState({ recipients });
      // console.log("recipients", recipients);
    }
  }

  onChange(e) {
    let tmp = { [e.target.name]: e.target.value.trim() };
    if (tmp) {
      this.setState(tmp);
    }
  }

  handleCheck(e) {
    const value = e.target.checked ? true : false;

    const { info } = this.props.status;
    if (info) {
      const data = {
        ...info,
        showInfo: value,
      };

      console.log('DATA', data);
      this.props.dispatch(updateStatus(status_const.SET_INFO, data));
    }
    this.setState({ check: value });
  }

  addDeliveryNote(e) {
    e.preventDefault();
    console.log('addDeliveryNote');
    const value = !this.state.checkDelivery;
    let data = null;
    let { info } = this.props.status;
    const text = value ? notaDelivery : '';
    if (info) {
      info.note = text;
      data = {
        ...info,
        showInfo: value,
      };
    } else {
      data = {
        note: text,
        showInfo: value,
      };
    }

    console.log('DATA', data);
    this.props.dispatch(updateStatus(status_const.SET_INFO, data));

    this.setState({ checkDelivery: value });
    this.setState({ check: value });
  }

  async doAction(type, isInvoice, message) {
    const { recordId } = this.state;
    console.log('doAction', recordId);
    const { place } = this.props;
    const { summary, info, discount } = this.props.status;
    const { recipients, note } = this.state;

    console.log('SUMMARY', summary);

    const total = calculateTotal(summary);

    try {
      const data = {
        total,
        place,
        recipients,
        info,
        note,
        isInvoice,
        summary,
        discount,
      };

      console.log('check movements');
      let movements = [];
      if (isInvoice && !recordId) {
        movements = summary.reduce((list, item) => {
          if (item.isExternalArticle) {
            let code = item.id;
            let store = place.id;
            let amount = item.quantity;
            let action = 'rem';
            let movement = { code, store, amount, action };
            list.push(movement);
          }
          return list;
        }, []);
      }
      console.log('movements', movements);
      console.log('sendToServer', data);
      const result = await sendToServer(data, type, recordId);
      console.log('RESULT', result);
      const { id, url } = result;
      // if (url) window.open(url, "_blank");
      const currentId = id ? id : recordId;

      if (movements.length > 0) {
        const movementPromises = movements.map((m) => {
          return productMovement(m);
        });
        const movementResults = await Promise.all(movementPromises);
        console.log('movementResults', movementResults);
      }

      this.setState({
        loading: false,
        link: result.url,
        recordId: currentId,
        message,
      });

      // console.log(data)
    } catch (e) {
      this.setState({
        loading: false,
        message: 'errors.mail_error',
      });
    }
  }

  openUrl(id) {
    const url = `${ENDPOINT_URL}/summaries/print/${id}`;
    window.open(url, '_blank');
  }

  //PRINT
  print(isInvoice = false) {
    const type = 'print';
    const message = 'dialogs.print_created';
    this.setState({ loading: true, message: null });
    this.doAction(type, isInvoice, message);
  }

  //SAVE
  save(isInvoice = false) {
    console.log('SAVE');
    const type = 'save';
    const message = isInvoice
      ? 'dialogs.invoice_created'
      : 'dialogs.summary_created';
    this.setState({ loading: true, message: null });
    this.doAction(type, isInvoice, message);
  }

  //MAIL
  async mail(e, isInvoice = false) {
    e.preventDefault();
    const type = 'mail';
    const message = 'dialogs.mail_sent';
    const { recipients } = this.state;
    if (!recipients || recipients.length <= 0) return;

    this.setState({ loading: true, message: null });
    await this.doAction(type, isInvoice, message);
    this.setState({ showMailForm: false });
  }

  async scloby(id) {
    this.setState({ loading: true, message: null });
    await sendToScloby(id);
    this.setState({
      loading: false,
      message: 'Controlla la cassa',
      sclobed: true,
    });
  }

  renderMailForm(isInvoice) {
    const { loading } = this.state;
    return (
      <form onSubmit={(e) => this.mail(e, isInvoice)}>
        <div className="full-w">
          <div className="full-w">
            <label className="label">
              <FormattedMessage id="labels.recipients" />
            </label>
            <input
              type="email"
              className="input"
              name="recipients"
              onChange={this.onChangeRecipients.bind(this)}
              placeholder={this.state.recipients}
            />
          </div>
          <div className="full-w">
            <label className="label">
              <FormattedMessage id="labels.email_message" />
            </label>
            <textarea
              className="input"
              name="note"
              onChange={this.onChange.bind(this)}
              style={{ minHeight: '150px' }}
            />
          </div>
          {!!loading && <div>invio...</div>}
          {!loading && (
            <button type="submit" className="btn-action btn-big mx1 mb1">
              Invia Mail
            </button>
          )}
        </div>
      </form>
    );
  }
  render() {
    let { loading, message, recordId, showMailForm, sclobed } = this.state;
    let show_buttons = !(loading || message);
    const { status, modal_type, place } = this.props;
    const directive = status.info && status.info.note ? status.info.note : null;
    const isInvoice = modal_type === modal_constants.MODAL_INVOICE;

    return (
      <div className="flex flex-center items-center flex-column justify-center">
        <div className="m1">{isInvoice ? 'ORDINE' : 'PREVENTIVO'}</div>
        {recordId && <div> Numero: {recordId}</div>}

        {loading && (
          <div>
            <FormattedMessage id="actions.loading" />
          </div>
        )}
        {message && (
          <div className="flex flex-center items-center flex-column justify-center ">
            <div className="m1 result-message">
              {message && <FormattedMessage id={message} />}
            </div>
          </div>
        )}

        {show_buttons && directive && (
          <div
            style={{
              maxWidth: '90%',
              textAlign: 'center',
              padding: 15,
              marginBottom: 10,
            }}
          >
            <p>
              <FormattedMessage id="labels.customer_notes" />
            </p>
            <p>{directive}</p>
            <input
              type="checkbox"
              onChange={(e) => this.handleCheck(e)}
              checked={this.state.check}
            />
            INCLUDI NEL PDF
          </div>
        )}

        {show_buttons && (
          <div
            style={{
              maxWidth: '90%',
              textAlign: 'center',
              padding: 15,
              marginBottom: 10,
            }}
          >
            <button className="btn" onClick={(e) => this.addDeliveryNote(e)}>
              {this.state.checkDelivery
                ? `Rimuovi nota delivery`
                : `Aggiungi nota delivery`}
            </button>
          </div>
        )}

        {!recordId && (
          <button
            className="btn-action btn-big mx1"
            onClick={(e) => this.save(isInvoice)}
          >{` REGISTRA `}</button>
        )}

        {!!recordId && !!showMailForm && this.renderMailForm(isInvoice)}
        {!!recordId && !showMailForm && (
          <div className="full-w">
            <div className="flex flex-center items-center justify-around mt2 mb2">
              <div>
                <button
                  className="btn-round"
                  onClick={(e) => this.setState({ showMailForm: true })}
                >
                  <FiMail className="react_icon" />
                </button>
                <span> invia per mail</span>
              </div>

              <div>
                <button
                  className="btn-round"
                  onClick={(e) => this.openUrl(recordId)}
                >
                  <FiMail className="react_icon" />
                </button>
                <span>Apri PDF</span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <button
                onClick={() => this.reset()}
                className="btn-action btn mx1 mb1"
              >
                <FormattedMessage id="actions.reset" />
              </button>

              {/*
              <button
                onClick={() => this.scloby(recordId)}
                className="btn-action btn-big mx1 mb1"
              >
                SCLOBY
              </button>
              */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    place: state.ui.place,
    status: state.status,
  };
}

export default connect(mapStateToProps)(ChooseAction);
