import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import usePortal from "react-useportal";
import { connect } from "react-redux";

import { switchLocale, switchPlace } from "../store/reducers/ui";
import * as status_const from "../store/reducers/status";
import { updateStatus } from "../store/reducers/status";
import * as modal_constants from "../utils/modal_constants";
import logo from "../assets/images/logo.png";

import SearchUser from "./search_user";
import SearchDoc from "./search_doc";
import LoadDoc from "./load_doc";
import SearchProduct from "./search_product";
import { searchUser } from "../api";

import {
  FiSearch,
  FiEdit,
  FiUser,
  FiFilter,
  FiFileText,
  FiUsers,
  FiBox,
  FiCopy,
  FiExternalLink,
  FiMaximize
} from "react-icons/fi";

function MegaSearch(props) {
  const { dispatch, info, closePortal } = props;
  const sections = [
    "Clienti",
    "Ordini/Preventivi",
    "Articoli Magazzino"
    // "Scan Articoli Magazzino"
  ];
  const [sectionIndex, setSectionIndex] = useState(0);
  function slugify(text) {
    return text
      .toLowerCase()
      .split(" ")
      .join("_");
  }
  function getIcon(index) {
    switch (index) {
      case 0:
        return <FiUsers className="react_icon" />;
      case 1:
        return <FiFileText className="react_icon" />;
      case 2:
        return <FiBox className="react_icon" />;
      // case 3:
      //   return <FiMaximize className="react_icon" />;
      default:
        return null;
    }
  }

  function showSerarch() {
    let content = null;
    if (sectionIndex === 0) {
      content = (
        <div>
          <SearchUser onCancel={closePortal} />
        </div>
      );
    } else if (sectionIndex === 1) {
      content = (
        <div>
          <SearchDoc onCancel={closePortal} />{" "}
        </div>
      );
    } else if (sectionIndex === 2) {
      content = (
        <div>
          <SearchProduct onCancel={closePortal} />{" "}
        </div>
      );
      // } else if (sectionIndex === 3) {
      //   content = <LoadDoc onCancel={closePortal} />;
    }
    return (
      <div>
        <div className="mx3 mt1">
          <h2>{sections[sectionIndex]}</h2>
        </div>
        <div>{content}</div>
      </div>
    );
  }
  return (
    <div id="modal" className="modal-container active">
      <div className="modal-backdrop border">
        <div className="modal-content">
          <div className="modal-close cancel" onClick={closePortal}>
            <span className="icon-medium icon-cross-white" />
          </div>
          <div className="flex items-center justify-center">
            <span className="h2 center mt2 mb2">Cerca</span>
          </div>
          <div className="modal-content-body">
            <div
              style={{
                minWidth: "80vw",
                minHeight: "90vh",
                width: "100%",
                padding: 10
              }}
            >
              <div className="flex items-center justify-center">
                {sections.map((s, i) => {
                  return (
                    <button
                      key={slugify(s)}
                      className={`btn ${
                        sectionIndex === i ? "btn-selected" : ""
                      } mr2 `}
                      onClick={e => setSectionIndex(i)}
                    >
                      <div className="flex items-center justify-center">
                        <div>{getIcon(i)}</div>
                        <div>{s}</div>
                      </div>
                    </button>
                  );
                })}
              </div>
              <div className="flex flex-column">{showSerarch()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    locale: state.ui.locale,
    place: state.ui.place,
    places: state.srv.places,
    info: state.status.info
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchLocale: locale => dispatch(switchLocale(locale)),
    switchPlace: place => dispatch(switchPlace(place)),
    updateStatus: (type, data) => dispatch(updateStatus(type, data))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MegaSearch);
