import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import * as status_const from '../store/reducers/status';
import { updateStatus } from '../store/reducers/status';
import classNames from 'classnames';
import { v4 } from 'node-uuid';
import _ from 'lodash';
import * as utils from '../utils/utils';

class Steps extends Component {
  _measure() {
    let el = findDOMNode(this.refs.col_0);
    const { clientWidth } = el; //this.saveRef;
    return clientWidth;
  }

  scroll_left(level) {
    var amount = this._measure();
    // console.log("SCROLL LEFT", amount, level);
    // const el = findDOMNode(this.refs.content);
    // let offset = $(el).scroll().left;
    // console.log("offset", offset);
    // $(el).animate({ scrollLeft: +(amount * level) }, 500);
    // $(el).scrollLeft(amount*level);
    this.props.scrollParent(amount * level);
  }

  componentDidUpdate(prevProps, prevState) {
    let level = null;
    if (this.props.status && this.props.status.level) {
      level = this.props.status.level;
    }
    if (level && level > 1 && prevProps.level !== level) {
      console.log('SCROLL LEFT');
      this.scroll_left(level);
    }
  }

  next_step(step) {
    if (step.type === status_const.CAT) {
      let current_item = null,
        { level } = step,
        { selected_categories } = this.props.status;
      selected_categories = selected_categories.slice(0, level + 1);
      selected_categories[level + 1] = step;

      this.props.updateStatus(status_const.SET_STEP, {
        selected_categories,
        level,
        current_item,
        // summary
      });
    } else {
      // SERVICE
      let current_item = _.cloneDeep(step);
      let { selected_categories } = this.props.status;
      //let { groups } = this.props.tree;

      current_item.uuid = v4();
      current_item.quantity = 1; //default qt?
      current_item.unit = _.cloneDeep(current_item.service.unitPrices[0]);
      current_item.descr = `${current_item.unit.unit.title} ${current_item.quantity}`;
      current_item.steps = _.cloneDeep(selected_categories);
      current_item = utils.getPrice(_.cloneDeep(current_item), 1);
      current_item.scloby = _.cloneDeep(current_item.service.scloby);

      console.log('ADD SERVICE', current_item);
      this.props.updateStatus(status_const.SET_CURRENT, current_item);
    }
  }

  render_step(step) {
    let level = step ? step.level : 0;
    let current_level = step ? step.level : -1;
    let parent = step.parent ? step.parent : -1;
    let key = step.key ? step.key : v4();
    let items = this.props.tree.flatten
      .filter((c) => {
        if (c.parent === parent) {
          c.type = status_const.CAT;
          return c;
        }
        return null;
      })
      .filter((i) => i != null);
    items.sort((a, b) => a.position - b.position);
    if (items.length === 0) {
      let leafs = this.props.tree.flatten
        .filter((c) => {
          if (c.id === parent) {
            c.type = status_const.CAT;
            return c;
          }
          return null;
        })
        .filter((i) => i != null);
      let leaf = leafs[0];

      if (leaf && leaf.services) {
        items = leaf.services.map((s) => {
          s.type = status_const.SRV;
          s.level = level + 1;
          return s;
        });
      }
    }
    // items  = this.getSubTree( level, parent );
    // console.log(`items: level=${level} parent=${parent}  `, items);
    // ref={div => { this.saveRef = div;}}
    let ref_name = level === -1 ? 'first_col;' : 'col_' + level;
    let { selected_categories, current_item } = this.props.status;
    let selection = selected_categories.filter((f) => f.id).map((f) => f.id);
    let dimmed =
      selection.length > 0 && current_level !== this.props.status.level;

    if (current_level < 0 && selection.length > 0) dimmed = true;

    return (
      <div key={key} className="layout__columns" ref={ref_name}>
        <div className="column column_width">
          {items.map((item, index) => {
            let checked =
              (current_item && current_item.id === item.id) ||
              selection.indexOf(item.id) > -1;
            let btnClass = classNames({
              'btn-step': true,
              'btn-step-selected': checked,
              'btn-step-dimmed': dimmed,
            });

            const { id, level, type, title, position } = item;
            return (
              <button
                onClick={() =>
                  this.next_step({
                    id,
                    level,
                    parent: id,
                    type,
                    title,
                    position,
                    index,
                    key: key + '_pos_' + index,
                    service: item.type === status_const.SRV ? item : null,
                  })
                }
                className={btnClass}
                key={item.id}
              >
                <div className="btn-step-content">
                  <span>{item.title}</span>
                  <span>
                    {checked && <span className="icon icon-ok-black" />}
                  </span>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="layout_h" ref="content">
        {this.props.status.selected_categories.map((step) =>
          this.render_step(step)
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    place: state.ui.place,
    tree: state.srv.tree,
    places: state.srv.places,
    summary: state.ui.summary,
    status: state.status,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: (type, data) => dispatch(updateStatus(type, data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Steps);
