import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";
import { v4 } from "node-uuid";
import { FiAlertCircle } from "react-icons/fi";

import * as status_const from "../store/reducers/status";
import { updateStatus } from "../store/reducers/status";
import { searchProduct, searchProductByCode, lastProducts } from "../api";
import { articlePlaceholder } from "../utils/articlePlaceholder";

const SearchProduct = props => {
  const { dispatch, store, places } = props;
  const [results, setResults] = useState(null);
  const [last, setLast] = useState([]);
  const [term, setTerm] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const search = async e => {
    e.preventDefault();
    if ((!term && !code) || !store) return null;
    setLoading(true);

    let data = null;
    if (term) {
      data = await searchProduct(term, store.id);
    } else if (code) {
      data = await searchProductByCode(code, store.id);
    }
    console.log("data", data);
    setResults(data);
    setLoading(false);
  };

  const handleChange = e => {
    setTerm(e.target.value);
  };
  const handleChangeCode = e => {
    setCode(e.target.value);
  };

  const selectProduct = product => {
    if (!product) {
      return;
    }
    console.log("product", product);
    //CLONE PRODUCT INTO REAL OBJ
    const current_item = _.cloneDeep(articlePlaceholder);
    const { name, price, code } = product;

    const uuid = v4();
    current_item.uuid = uuid;
    current_item.key = uuid;
    current_item.id = code;
    current_item.service.id = code;

    current_item.title = `${name}`;
    current_item.customTitle = `${name} ${code}`;
    current_item.unit.price = price;
    current_item.unit.id = code;
    current_item.quantity = 1;

    // const { name: title, tel: phone, email } = user;
    // const payload = {
    //   ...info,
    //   title,
    //   email,
    //   phone
    // };
    // dispatch(updateStatus(status_const.SET_INFO, payload));

    console.log("ITEM", current_item);
    dispatch(updateStatus(status_const.SET_CURRENT, current_item));

    props.onCancel();
  };

  console.log("places", places);
  function getPlaceNick(id) {
    const result = places.find(p => p.id === id);
    return result ? result.nick : "-";
  }

  const retrieveLastProducts = async storeId => {
    const lastRecords = await lastProducts(storeId);
    console.log("retrieveLastProducts", lastRecords);
    setLast(lastRecords);
  };

  useEffect(() => {
    //last docs
    if (store) {
      retrieveLastProducts(store.id);
    }
  }, [store]);

  const reset = () => {
    setResults(null);
    setTerm("");
    setCode("");
  };

  const renderProduct = products => {
    return products.map(obj => {
      const { data: product } = obj;
      const { name, code, price, quantity, store } = product;
      const storeNick = getPlaceNick(store);

      const lowQuantity = product.quantity <= product.min_storage;
      return (
        <div
          className={"mb1 btn-simple"}
          key={obj.id}
          onClick={() => selectProduct(product)}
        >
          <div
            className={`flex flex-column p1  ${
              lowQuantity ? "redBorder" : "greenBorder"
            } `}
          >
            <div className="flex justify-between">
              <h4>{`${name}`}</h4>
              <span>{`${code}`}</span>
            </div>

            <div className="mv1">
              <strong>PU:</strong> {`${(price / 100).toFixed(2)} €`}
            </div>

            <div className="flex justify-between">
              <span>
                <strong>QT:</strong> {`${quantity}`}
              </span>
              {lowQuantity && (
                <FiAlertCircle
                  height={10}
                  className="react_icon"
                  alt="In esaurimento"
                  color="red"
                />
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-column flex-center m3">
      <div className="border p2">
        <form onSubmit={e => search(e)}>
          <div className="flex   items-center mb1">
            <div className="mr1">
              <label className="label">Nome Articolo</label>
              <input
                className="input "
                type="name"
                value={term}
                placeholder="nome articolo"
                onChange={e => handleChange(e)}
              />
            </div>
            <div className="mr1">
              <label className="label">Codice Articolo</label>
              <input
                className="input "
                type="code"
                value={code}
                placeholder="Codice articolo"
                onChange={e => handleChangeCode(e)}
              />
            </div>
            <div className="flex  items-center">
              <button className={"btn btn-small mr1"} type="submit">
                <FormattedMessage id="actions.search" />
              </button>
              <button
                className={"btn btn-small mr1"}
                type="reset"
                onClick={e => reset()}
              >
                X
              </button>
            </div>
          </div>
        </form>

        <div className="m1">
          {loading && <FormattedMessage id="actions.loading" />}
          {!!_.isEmpty(results) && <FormattedMessage id="labels.no_results" />}
        </div>

        {!_.isEmpty(results) && (
          <div>
            <h5>Risultati</h5>
            {renderProduct(results)}
          </div>
        )}
      </div>
      {_.isEmpty(results) && (
        <div className="mt2">
          <h5>Recenti</h5>
          {renderProduct(last)}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    store: state.ui.place,
    places: state.srv.places
  };
}

export default connect(mapStateToProps)(SearchProduct);
