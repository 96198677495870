import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";
// import * as status_const from "../store/reducers/status";
import * as modal_constants from "../utils/modal_constants";
import { addSummaryItem } from "../store/reducers/status";
import * as utils from "../utils/utils";
import { ReactComponent as IconEdit } from "../assets/icons/edit.svg";
import { ReactComponent as IconOk } from "../assets/icons/check.svg";
import { ReactComponent as IconCancel } from "../assets/icons/close.svg";

const StatusBar = props => {
  const [customTitle, setCustomTitle] = useState("");
  const [edit, setEdit] = useState(false);
  const [path, setPath] = useState("");
  const [related, setRelated] = useState(null);
  const { selected_categories, dispatch } = props;
  const { groups } = props;
  const [aware, setAware] = useState(null);
  let { current_item, summary, current_related } = props;
  const firstButton = useRef(null);

  useEffect(() => {
    if (firstButton.current) firstButton.current.focus();
  }, [firstButton]);

  useEffect(() => {
    setPath(utils.getBreadCrumbs(selected_categories, ""));
    return () => {
      setPath("");
    };
  }, [selected_categories]);

  useEffect(() => {
    if (current_item) {
      const result = utils.getPriceWithSummary(
        current_item,
        current_related,
        summary,
        groups
      );

      let ct = null;
      if (aware && aware.id !== current_item.id) {
        setCustomTitle(null);
      } else {
        ct =
          customTitle || current_item.customTitle
            ? customTitle
              ? customTitle
              : current_item.customTitle
            : null;
        setCustomTitle(ct);
      }

      setPath(utils.getBreadCrumbs(selected_categories, current_item.title));

      setAware(result.current_item);
      setRelated(result.related);
      return () => {
        setCustomTitle(null);
      };
    } else {
      setRelated(null);
      setAware(current_item);
    }
  }, [
    current_item,
    current_related,
    summary,
    aware,
    customTitle,
    groups,
    selected_categories
  ]);

  async function addToSummary() {
    let item = _.cloneDeep(aware);
    if (item.custom_total) item.color = "red";
    if (customTitle !== item.service.title) item.customTitle = customTitle;
    item.path = path;
    item.index = summary.length;
    let s = _.cloneDeep(summary);
    s.push(item);
    s = await utils.recalc(s, groups);
    const total = utils.calculateTotal(s);

    dispatch(addSummaryItem({ summary: s, total, groups }));
  }

  function choose_quantity() {
    props.showCalculator(modal_constants.MODAL_QUANTITY);

    // props.dispatch(updateSummary(summary))
  }
  function choose_price() {
    props.showCalculator(modal_constants.MODAL_PRICE);
  }

  function choose_price_unit() {
    props.showCalculator(modal_constants.MODAL_PRICE_UNIT);
  }

  function showCurrentInfo() {
    if (!(aware && aware.unit)) return null;

    const { unit, service, amount, total, custom_total, quantity } = aware;

    return (
      <div className="flex mr4">
        <b className="mr1">CURRENT</b>
        <div>
          <span>
            <b>PU</b> {` ${unit.price.toFixed(3)} `}
          </span>
          {unit.range && (
            <span>{` [${unit.range.min}-${unit.range.max}] `}</span>
          )}
        </div>
        <div>
          <span>
            <b className="mr1">AMOUNT</b> {` ${amount.toFixed(3)} `}
          </span>

          <span>
            <b className="mr1">TOT</b> {` ${total.toFixed(3)} `}
          </span>

          {custom_total && (
            <span>
              <b className="mr1">CUSTOM_TOT</b> {` ${custom_total.toFixed(3)} `}
            </span>
          )}
        </div>

        {service && service.group && (
          <div>
            <b className="mr1">GROUP</b>
            {` ${service.group.title} x${service.groupMultiplier}`}
          </div>
        )}
      </div>
    );
  }

  function relatedInfo() {
    if (!aware) return null;
    const { quantity } = aware;
    return (
      <div className="mr4 flex">
        <b className="mr1">RELATED:</b>
        {related && (
          <span>
            <b className="mr1">QT</b>
            {`${related.qt ? related.qt - quantity : 0}`}
          </span>
        )}
        {related && (
          <span>
            <b className="mr1">UP</b>
            {up(related.unit)}
          </span>
        )}
      </div>
    );
  }

  function priceInfo() {
    if (!aware) return null;
    const { service } = aware;

    if (!(service && service.unitPrices.length>0)) return null;

    return (
      <div className="ml1 flex flex-column">
        <div className="flex">
          <b className="mr1">Scaglioni</b>
          <span>{ups(service)}</span>
        </div>
      </div>
    );
  }

  function ups(service) {
    if (!service.unitPrices) return null;
    let multiplier = service.group ? service.groupMultiplier : 1;
    return service.unitPrices.map(u => up(u, multiplier));
  }

  function up(unit, multiplier = 1) {
    if (!unit) return null;
    const value = (unit.price * multiplier) / 100;
    return (
      <span key={unit.id} className="mr1">
        <b>{`${value.toFixed(3)} € `}</b>
        <span>
          {unit.range ? ` [${unit.range.min}-${unit.range.max}]` : ""}
        </span>
      </span>
    );
  }

  const currencyProps = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
  };

  function renderEditName() {
    return (
      <div style={{ width: "100%" }}>
        {aware && aware.service && (
          <div>
            {!edit && (
              <div className="flex items-center">
                <span className="status-subtitle mx1">
                  {customTitle ? customTitle : ""}
                </span>
              </div>
            )}

            {edit && (
              <div
                className="flex items-center"
                style={{ flex: 1, width: "100%" }}
              >
                <input
                  type="text"
                  className="mr1  status__input"
                  value={customTitle ? customTitle : ""}
                  onChange={e => setCustomTitle(e.target.value)}
                />
                <button
                  className="mr1 btn-round--dark flex"
                  onClick={e => setEdit(!edit)}
                >
                  <IconOk style={{ width: 18, height: 18, fill: "#fcd142" }} />
                </button>
                <button
                  className="mr1 btn-round"
                  onClick={e => {
                    setCustomTitle(null);
                    setEdit(!edit);
                  }}
                >
                  <IconCancel style={{ width: 18, height: 18 }} />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="status">
      <div className="status-text">
        <span className="status-title">{path}</span>
        <span>
          {aware && aware.service && !edit && (
            <button
              className="mr1 btn-round"
              onClick={e => setEdit(!edit)}
              style={{ display: "inline-block" }}
            >
              <IconEdit style={{ width: 18, height: 18 }} />
            </button>
          )}
        </span>
      </div>
      <div className="flex mt1 justify-between">
        {renderEditName()}
        <div />

        <div className="status-content">
          {aware && (
            <div className="flex items-center">
              <button
                className="btn-action mr2"
                tabIndex="1"
                onClick={() => choose_quantity()}
                ref={firstButton}
              >
                <span>
                  {aware.unit.unit.title}
                  <span className="ml1">{aware.quantity}</span>
                </span>
              </button>

              <button
                className="btn-action mr2"
                onClick={() => choose_price_unit()}
                tabIndex="2"
              >
                <span className="mr1">
                  <FormattedMessage id="labels.price_x_unit" />
                </span>
                <span>
                  <FormattedNumber
                    {...currencyProps}
                    value={utils.getItemPrice(aware)}
                  />
                </span>
              </button>

              <button
                className="btn-action mr2"
                onClick={() => choose_price()}
                tabIndex="3"
              >
                <FormattedNumber
                  {...currencyProps}
                  value={utils.getItemTotal(aware)}
                />
              </button>
              <button
                className="btn-action--dark mr1"
                onClick={() => addToSummary()}
                tabIndex="4"
              >
                <div className="flex items-center justify-center">
                  <span className="icon-normal icon-plus-yellow accent mr1" />
                  <FormattedMessage id="actions.add" />
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex mt1 mb1 justify-between ">
        <div className="flex">{priceInfo()}</div>
      </div>
    </div>
  );
};

function mapStateToProps({ srv, status }) {
  const {
    current_item,
    summary,
    selected_categories,
    current_related
  } = status;
  const { groups } = srv.tree;
  return {
    current_item,
    summary,
    selected_categories,
    groups,
    current_related
  };
}

export default connect(mapStateToProps)(StatusBar);
