import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import moment from 'moment';
import $ from 'jquery';

import { loadAll, loadCache } from '../store/reducers/srv';
import { addSummaryItem, updateStatus } from '../store/reducers/status';
import { loadStorageCache } from '../utils/storage';
import * as status_const from '../store/reducers/status';
import * as modal_constants from '../utils/modal_constants';
import { getSummary } from '../api';

import Header from './header';
import StatusBar from './status_bar';
import Summary from './summary';
import Steps from './steps';
import ModalContainer from './modal_container';

const { REACT_APP_CACHE_HOURS } = process.env;

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal_type: null,
      modal_params: {},
    };
    this.scroll_parent = this.scroll_parent.bind(this);
    this.showCalculator = this.showCalculator.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  componentDidMount() {
    const tmp = loadStorageCache();
    const cache = tmp ? tmp.srv : null;
    const when = cache ? cache.when : 0;
    const elapse_time = 1000 * 60 * 60 * REACT_APP_CACHE_HOURS;
    let diff = 0;
    if (cache) diff = Date.now() - elapse_time - when;
    console.log('CACHE WINDOW IN HOURS', REACT_APP_CACHE_HOURS);
    const d1 = moment(when);
    const d2 = moment();
    const duration = d2.diff(d1, 'hours');
    console.log('HOURS ELAPSED FROM LAST UPDATE', duration);

    if (diff < 0) {
      console.log('LOAD FROM CACHE');
      this.props.loadCache(cache);
    } else {
      console.log('LOAD FROM DATO');
      this.props.loadAll();
    }

    if (
      this.props.location &&
      this.props.location.search.indexOf('load') > -1
    ) {
      const params = this.props.location.search.split('=');
      const id = params[1];
      console.log('LOAD PREVIOUS SUMMARY', id);
      this.loadSummary(id);
    }
  }

  async loadSummary(id) {
    const result = await getSummary(id);
    if (result && result.data) {
      const { summary, total, info, discount } = result.data;
      this.props.addSummaryItem({ summary, total });
      if (discount) {
        this.props.updateStatus(status_const.SET_DISCOUNT, { discount });
      }
      if (info) {
        this.props.updateStatus(status_const.SET_INFO, info);
      }
    }
  }

  scroll_parent(x) {
    const el = findDOMNode(this.refs.content_root);
    $(el).animate({ scrollLeft: +x }, 500);
  }

  showCalculator(type = modal_constants.MODAL_QUANTITY) {
    this.setState({ modal: true, modal_type: type });
  }

  showModal(type, params = {}) {
    this.setState({ modal: true, modal_type: type, modal_params: params });
  }

  modalClik() {
    this.setState({ modal: false, modal_type: null });
  }

  modalClose() {
    this.setState({ modal: false, modal_type: null });
  }

  footer() {
    return (
      <div className="floating_footer">
        <button
          className="mr1 btn-link-dark"
          onClick={() => this.showModal(modal_constants.MODAL_RESET)}
        >
          <FormattedMessage id="actions.reset" />
        </button>
        <button
          onClick={() => this.showModal(modal_constants.MODAL_RESET)}
          className="btn-oval--dark mr2"
        >
          <span className="icon-medium icon-number-Copy-5 accent" />
        </button>
      </div>
    );
  }

  renderTitle() {
    let { info } = this.props;
    let title = info ? info.title : 'Copisteria Universale';
    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  }

  render() {
    if (!this.props.tree) {
      return (
        <div className="wrap">
          <span className="full-w full-h h4 center mt1">
            <FormattedMessage id="actions.loading" />
          </span>
        </div>
      );
    }

    return (
      <div className="wrap">
        {this.renderTitle()}
        <Header showModal={this.showModal} />
        <div className="layout__body">
          <div className="layout_v">
            <StatusBar
              showCalculator={this.showCalculator}
              showModal={this.showModal}
            />
            <div className="layout__content" ref="content_root">
              <Steps scrollParent={this.scroll_parent} />
            </div>
            {this.footer()}
          </div>
          <Summary showModal={this.showModal} />
        </div>

        {/*this.state.modal && this.renderModal()*/}
        {this.state.modal && (
          <ModalContainer
            modal_type={this.state.modal_type}
            modal_params={this.state.modal_params}
            modalClose={this.modalClose.bind(this)}
            modalClik={this.modalClik.bind(this)}
          />
        )}
        {this.state.overlay && this.renderOverlay()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tree: state.srv.tree,
    places: state.srv.places,
    place: state.ui.place,
    info: state.status.info,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addSummaryItem: (data) => dispatch(addSummaryItem(data)),
    loadAll: () => dispatch(loadAll()),
    loadCache: (data) => dispatch(loadCache(data)),
    updateStatus: (type, data) => dispatch(updateStatus(type, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
