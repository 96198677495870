import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import moment from "moment";
import Home from "../components/home";
import Login from "../components/login";
import { ping } from "../api";

class Routes extends Component {
  // shouldComponentUpdate(nextProps) {
  //   let { auth } = this.props;
  //   const upd = auth ? auth != nextProps.auth : nextProps.auth ? true : false;
  //   console.log("should update ? ", upd);
  //   return upd;
  // }

  componentDidMount() {
    let { auth } = this.props;
    let now = moment().format("x");
    let logged = auth && auth.expire && auth.expire > now;
    if (logged) {
      console.log("check auth");
      ping();
    }
  }

  render() {
    let { auth } = this.props;
    let now = moment().format("x");
    let logged = auth && auth.expire && auth.expire > now;
    // console.log("logged", logged);
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route
            path="/"
            render={(routerProps) =>
              logged ? <Home {...routerProps} /> : <Login />
            }
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.ui.auth,
  };
}

export default connect(mapStateToProps)(Routes);
