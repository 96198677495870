export const loadState = () => {
  try {
    let json = localStorage.getItem("state");
    if (json) {
      return JSON.parse(json);
    } else {
      return undefined;
    }
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    localStorage.setItem("state", JSON.stringify(state));
  } catch (err) {
    console.log(err);
  }
};

export const removeState = () => {
  try {
    localStorage.removeItem("state");
  } catch (err) {
    console.log(err);
  }
};

export const loadStorageCache = () => {
  try {
    let json = localStorage.getItem("cache");
    if (json) {
      return JSON.parse(json);
    } else {
      return undefined;
    }
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const saveCache = (cache) => {
  try {
    localStorage.setItem("cache", JSON.stringify(cache));
  } catch (err) {
    console.log(err);
  }
};

export const removeCache = () => {
  try {
    localStorage.removeItem('cache');
  } catch (err) {
    console.log(err);
  }
};
